import "./style.css";
import { Link } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { Bar, Radar, Pie, PolarArea, Line } from "react-chartjs-2";

// firebase functions
import {
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../../firebase";

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

function LandingPage() {
  const [selectedValue, setSelectedValue] = useState("SANTA ISABEL");
  const [filter, setFilter] = useState([]);

  //
  const [municipalities, setMunicipalities] = useState([]);

  function changedata(mun) {
    return municipalities.filter((d) => d.municipio === mun);
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);

    setFilter(changedata(event.target.value));
  };

  useEffect(() => {
    (async () => {
      const q = query(collection(db, "municipalities"));
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });

      setMunicipalities(docs);
      setFilter([docs[0]]);

      console.log("doxs", docs);
    })();
  }, []);

  const { user } = useUserContext();

  const isLogged = "/panel";
  const isNotLogged = "/signin";

  return (
    <div className="conte">
      <header class="cabeceraPrincipal">
        <section class="logotipo">
          <img src={require("./images/landing_logo_dark.png")} />
        </section>
        <div class="respmenu">
        <input type="checkbox" />
        <i class="fas fa-bars"></i>
        <i class="fas fa-times"></i>
        <nav class="menuPrincipal1">
          <a href="#importantes">Dimensiones</a>
          <a href="#graficas">Indicador</a>
          <a href="#objetivo">Escala</a>
          <a href="/signin/">
            <button class="botonMenu1 solo-movil">
              <Link to={user ? isLogged : isNotLogged}>Ingreso </Link>
            </button>
          </a>
          <a href="/signup">
            <button class="botonMenu2 solo-movil">Registro</button>
          </a>
        </nav>
      </div>
        <nav class="menuPrincipal2">
          <a href="/signin/">
            <button class="botonMenu1 solo-pc">
              <Link to={user ? isLogged : isNotLogged}>Ingreso </Link>
            </button>
          </a>
          <a href="/signup">
            <button class="botonMenu2 solo-pc">Registro</button>
          </a>
        </nav>
      </header>

      <section class="sliderPrincipal">
        <img src={require("./images/portada1.jpg")} title="" />
        <div class="textoGrande">
          <b>
            Municipios
            <br />
            Sustentables
          </b>
        </div>
        <div class="textoPequeño">
          <br />
          <p>
            Lograr que las ciudades sean más inclusivas,
            <br />
            seguras, resilientes y sostenibles. 
          </p>
        </div>
        <div class="botonesPortada">
          <a href="/">
            <button class="botonPortada1">
              <Link to={user ? isLogged : isNotLogged}>INGRESAR</Link>
            </button>
          </a>
          <a href="#">
            <button class="botonPortada2">CONOCER MÁS</button>
          </a>
        </div>
      </section>

      <section class="cards" id="importantes">
        <p class="team">DIMENSIONES</p>
        <div class="cards-container">
          <div class="card">
            <img src={require("./images/economics.jpg")} />
            <h3>Económica</h3>
            <p>
              En este grupo se incluye las variables relacionadas a la evolución de las características económicas desde el punto de vista de los ingresos recibidos por los GAD’s correspondientes a actividades de prestación de servicios y a ingresos recibidos para protección ambiental.
            </p>

          </div>
          <div class="card">
            <img src={require("./images/social-photo.jpg")} />
            <h3>Social</h3>
            <p>
              Corresponden al patrimonio de áreas verdes o de
              zonas terrestres, manejado directamente por entes públicos o de competencias de los
              GAD’s municipales. Por lo tanto, en este grupo se evalúa las áreas verdes, considerando
              como superficie de las mismas, los parques entre otras.
            </p>
          </div>
          <div class="card">
            <img src={require("./images/ambiental.jpg")} />
            <h3>Ambiental</h3>
            <p>
              Ofrece una síntesis
              de los aspectos que indicen en el entorno , los indicadores que
              integran este grupo corresponde a las áreas que ocupan los relleno sanitarios, recolección
              de residuos, fuentes de captación de agua, tratamiento de las aguas residuales y el
              consumo total de agua.
            </p>
          </div>

          <div className="card">
            <img src={require("./images/inst.jpg")}/>
            <h3>Institucional</h3>
            <p>
              En este grupo se encuentran todos aquellos
              factores relacionados con la estructura y el desarrollo, el funcionamiento y la organización
              de los GAD’s municipales, toma en cuenta variables como el número de funcionarios que
              laboran en actividades de gestión ambiental, consumo de energía y la cantidad de productos
              maderables.
            </p>
          </div>

        </div>
      </section>

      <section class="carrusel">
        <div
          id="carouselExampleCaptions"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src={require("./images/slider1.jpg")}
                class="d-block w-100"
                alt="fondo1"
              />
              <div class="carousel-caption d-none d-md-block">
                <h3 >¿Conceptos a entender?</h3>
                <h6>
                  ¿Qué es un GAD?
                </h6>
                <p>
                  Los Gobiernos Autónomos Descentralizados (GAD), <br/>
                  son las instituciones que conforman la organización <br/>
                  territorial del Estado Ecuatoriano y están regulados <br/>
                  por la Constitución de la República del Ecuador (Art. 238-241) <br/>
                  y el Código Orgánico de Organización Territorial, <br/>
                  Autonomías y Descentralización (COOTAD).
                </p>
                <img class="sli" src={require("./images/sliderFoto1.jpg")} />
              </div>
            </div>
            <div class="carousel-item">
              <img
                src={require("./images/slider2.jpg")}
                class="d-block w-100"
                alt="fondo2"
              />
              <div class="carousel-caption d-none d-md-block">
                <h3 >¿Conceptos a entender?</h3>
                <h6>
                  Funciones y Competencias de los GADS
                </h6>
                <p>
                  <ul>
                    <li>

                  Promover el desarrollo sustentable de su circunscripción
                   territorial cantonal, para garantizar la realización
                  del buen vivir a través de la implementación de políticas
                  públicas cantonales, en el marco de sus competencias constitucionales y legales.
                  </li>

                    <li>
                      Delimitar, regular, autorizar y controlar el uso de las playas de mar,
                      riberas y lechos de ríos, lagos y lagunas, sin perjuicio de las
                      limitaciones que establezca la ley.

                    </li>
                    <li>Preservar y garantizar el acceso efectivo de las personas al uso de
                      las playas de mar, riberas de ríos, lagos y lagunas.</li>

                    <li>
                      Regular, autorizar y controlar la explotación de materiales áridos y
                      pétreos, que se encuentren en los lechos de los ríos, lagos, playas de
                      mar y canteras.
                    </li>
                  </ul>

                </p>
              </div>
            </div>
            {/*<div class="carousel-item">*/}
            {/*  <img*/}
            {/*    src={require("./images/slider3.jpg")}*/}
            {/*    class="d-block w-100"*/}
            {/*    alt="fondo3"*/}
            {/*  />*/}
            {/*  <div class="carousel-caption d-none d-md-block">*/}
            {/*    <h3 >¿Conceptos a entender?</h3>*/}
            {/*    <h6>*/}
            {/*      Escala de Valoración*/}
            {/*    </h6>*/}
            {/*   <p className="col-12 mb-1">*/}
            {/*     <table className="table ">*/}
            {/*       <thead>*/}
            {/*       <tr>*/}

            {/*         <th  scope="col">ESCALA</th>*/}
            {/*         <th scope="col">VALOR (P)</th>*/}
            {/*         <th scope="col">CRITERIO DE CALORACIÓN</th>*/}
            {/*       </tr>*/}
            {/*       </thead>*/}
            {/*       <tbody>*/}
            {/*       <tr>*/}
            {/*         <td>Alta</td>*/}
            {/*         <td>≥75% - 100%</td>*/}
            {/*         <td>Incidencia considerada idónea para alcanzar el desarrollo sustentable municipal</td>*/}
            {/*       </tr>*/}

            {/*       <tr>*/}
            {/*         <td>Media</td>*/}
            {/*         <td>≥50% - 75%</td>*/}
            {/*         <td>Incidencia considerada considerada aceptable o limitada para alcanzar el desarrollo sustentable municipal</td>*/}
            {/*       </tr>*/}


            {/*       <tr>*/}
            {/*         <td>Baja</td>*/}
            {/*         <td>{"≥25% - <50%"}</td>*/}
            {/*         <td>Incidencia considerada incompatible para alcanzar el desarrollo sustentable municipal</td>*/}
            {/*       </tr>*/}


            {/*       <tr>*/}
            {/*         <td>Muy baja</td>*/}
            {/*         <td>{"<25% - 0%"}</td>*/}
            {/*         <td>Incidencia considerada inaceptable para alcanzar el desarrollo sustentable municipal</td>*/}
            {/*       </tr>*/}

            {/*       </tbody>*/}
            {/*     </table>*/}
            {/*   </p>*/}
            {/*    /!*<img class="sli" src={require("./images/sliderFoto3.jpg")} />*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      <section class="graficas" id="graficas">
        <p class="centroGrafica">GRÁFICAS DE CADA MUNICIPIO</p>
        <label for="dog-names">Escoja un Municipio:</label>

        <select value={selectedValue} onChange={handleChange}>
          {console.log("currentValuesCombo:", selectedValue)}
          {municipalities.map((i) => {
            return (
              <>
                <option value={i.municipio} key={i.id}>{i["municipio"]}</option>
              </>
            );
          })}
        </select>
        {console.log("filter", filter)}

        {filter
        .sort((a, b) => a.creadoEn - b.creadoEn)
        .map((f) => {
          const chartData = {
            labels: [
              "Índice ambiental",
              "Índice Social",
              "Índice economico",
              "Índice institucional",
            ],
            datasets: [
              {
                label: "Índices",
                data: [
                  f.indAmbiental,
                  f.indSocial,
                  f.indEconomico,
                  f.indInstitucional,
                ],

                backgroundColor: [
                  "rgba(255, 99, 132, 0.3)",
                  "rgba(54, 162, 135, 0.3)",
                  "rgba(255, 206, 86, 0.3)",
                  "rgba(75, 192, 192, 0.3)",
                ],

                borderWidth: 7,
              },
            ],
          };

          return (
            <>
              <div className="creado-en">
                  <h5>AÑO: <span className="creado-en-bold">{f.creadoEn}</span></h5>
              </div>
              <div className="lastReportData mt-5" key={f.id}>
                <div className="chart-container" style={{ width: "450px" }}>
                  <h5>GRÁFICA EN BARRAS</h5>
                  <Bar className="" data={chartData}  />
                </div>
                <div className="chart-container" style={{ width: "450px" }}>
                  <h5>GRÁFICA RADIAL</h5>
                  <Radar className="" data={chartData}  />
                </div>
              </div>
            </>
          );
        })}
      </section>

      
      <section class="objetivo" id="objetivo">
        <p class="centroObjetivo">ESCALA DE VALORACIÓN</p>
        <section class="tabla" id="tabla">
        <table class="mi-tabla">
          <tr>
            <td>ESCALA</td>
            <td>VALOR (P)</td>
            <td>CRITERIO DE VALORACIÓN</td>
          </tr>
          <tr>
            <td>Alta</td>
            <td>{'\u2265'}75% - 100%</td>
            <td>Incidencia considerada idónea para alcanzar el desarrollo sustentable municipal</td>
          </tr>
          <tr>
            <td>Media</td>
            <td>{'\u2265'}50% - 75%</td>
            <td>Incidencia considerada aceptable o limitada para alcanzar el desarrollo municipal</td>
          </tr>
          <tr>
            <td>Baja</td>
            <td>{'\u2265'}25% - {'\u003C'}50%</td>
            <td>Incidencia considerada incompatible para alcanzar el desarrollo sustentable municipal</td>
          </tr>
          <tr>
            <td>Muy Baja</td>
            <td>{'\u003C'}25% - 0%</td>
            <td>Incidencia considerada inaceptable para alcanzar el desarrollo sustentable</td>
          </tr>
        </table>
        </section>
      </section>

      <section class="sponsor" id="sponsor">
        <p class="centroSponsor">
          <b>¿Quiénes nos apoyan?</b>
        </p>
        <div class="imagenesSponsor">
          <img src={require("./images/sponsor2.jpg")} />
          <img src={require("./images/sponsor3.jpg")} />
        </div>
      </section>

      <footer class="piePagina">
        <img src={require("./images/fondoFooter.jpg")} />
        <div class="textoFooter1">
          <h4>Proyecto Carrera de Economía</h4>
          <p>

            Tercer Ciclo
            <br />
            Economía Ambiental y de los Recursos Naturales <br />
          </p>
        </div>
        <div class="textoFooter2">
          <h4>Información:</h4>
          <p>
            Diana Bravo Benavides <br />
            dbbravo@utpl.edu.ec<br />
            3701444 Ext.: 2558 <br />
          </p>
        </div>
        <div class="iconos">
          <a href="">
            <i class="fa-brands fa-facebook"></i>
          </a>
          <a href="">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="">
            <i class="fa-brands fa-tiktok"></i>
          </a>
        </div>
        <div class="centroDerechos">
          <h6>Derechos reservados 2022 - 2023</h6>
        </div>
      </footer>
      <div />

      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      ></script>
    </div>
  );
}

export default LandingPage;